import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { MenuItem } from 'primeng/api';
import { UserService } from '../../services/user/user.service';

@Component({
  selector: 'app-menubar',
  templateUrl: './menubar.component.html',
  styleUrls: ['./menubar.component.scss'],
})
export class MenubarComponent implements OnInit {
  items: MenuItem[] = [];
  userMenu: MenuItem[] = [];
  private userEmail = localStorage.getItem('email');
  permiso: any | null = null;
  constructor(private userService: UserService, private router: Router) {}
  

  ngOnInit(): void {
      // lógica para mostrar el menú adecuado según el control de acceso

      this.items = [
        {
          visible: this.userService.getPermiso(undefined,'') ?? false,
          label: 'Admimistrador',
          icon: 'pi pi-lock',
          items: [
            {
              label: 'Crear Usuario',
              icon: 'pi pi-fw pi-align-left',
              command: (event) => {
                this.router.navigate(['/user/new']);
              },
            },
            {
              label: 'lista de usuarios',
              icon: 'pi pi-fw pi-align-left',
              command: (event) => {
                this.router.navigate(['/user/list']);
              },
            },
            {
              label: 'email activar',
              icon: 'pi pi-fw pi-align-left',
              command: (event) => {
                this.router.navigate(['/user/activar']);
              },
            },
          ],
        },
        // clientes
        {
          visible: this.userService.getPermiso('customer','read') ?? false, 
          label: 'Clientes',
          icon: 'pi pi-users',
          items: [
            {
              visible: this.userService.getPermiso('customer','read') ?? false, 
              label: 'Listado Clientes',
              icon: 'pi pi-fw pi-align-left',
              command: (event) => {
                this.router.navigate(['/customers']);
              },
            },
            {
              visible: this.userService.getPermiso('customer','write') ?? false, 
              label: 'Nuevo Cliente',
              icon: 'pi pi-fw pi-align-left',
              command: (event) => {
                this.router.navigate(['/customers/new']);
              },
            },
            {
              visible: this.userService.getPermiso('customer','write') ?? false, 
              label: 'Contactos',
              icon: 'pi pi-fw pi-align-left',
              command: (event) => {
                this.router.navigate(['/customers/contact/new']);
              },
            },
          ],
        },
        // presupuestos
        {
          visible: this.userService.getPermiso('budget','read') ?? false,
          label: 'Presupuestos',
          icon: 'pi pi-folder-open',
          items: [
            {
              visible: this.userService.getPermiso('budget','read') ?? false,
              label: 'Listado',
              icon: 'pi pi-fw pi-align-left',
              command: (event) => {
                this.router.navigate(['/budgets']);
              },
            },
            {
              visible: this.userService.getPermiso('budget','write') ?? false,
              label: 'Nuevo',
              icon: 'pi pi-fw  pi-plus',
              command: (event) => {
                this.router.navigate(['/budgets/new']);
              },
            },
            {
              visible: this.userService.getPermiso('budget','write') ?? false,
              label: 'Materiales',
              icon: 'pi pi-fw pi-plus',
              command: (event) => {
                this.router.navigate(['/materials']);
              },
            },
            {
              visible: this.userService.getPermiso('budget','write') ?? false,
              label: 'Nuevo Material',
              icon: 'pi pi-fw pi-plus',
              command: () => {
                this.router.navigate(['/materials/new']);
              },
            },
          ],
        },
        // Albaranes
        {
          visible: this.userService.getPermiso('delivery','read') ?? false, 
          label: 'Albaranes',
          icon: 'pi pi-folder-open',
          items: [
            {
              visible:( this.userService.getPermiso('delivery','read')  ) ?? false, 
              label: 'Listado',
              icon: 'pi pi-fw pi-align-left',
              command: () => {
                this.router.navigate(['/delivery']);
              },
            },
            {
              visible: (this.userService.getPermiso('delivery','write') && this.userService.getPermiso('budget','write') )?? false, 
              label: 'Nuevo',
              icon: 'pi pi-fw pi-align-right',
              command: () => {
                this.router.navigate(['/delivery/new']);
              },
            },
          ],
        },
        {
          visible: this.userService.getPermiso('invoice','read') ?? false, 
          label: 'Facturas',
          icon: 'pi pi-folder-open',
          items: [
            {
              visible: this.userService.getPermiso('invoice','read') ?? false, 
              label: 'Listado',
              icon: 'pi pi-fw pi-align-left',
              command: () => {
                this.router.navigate(['/invoices']);
              },
            },
            {
              visible: this.userService.getPermiso('invoice','write') ?? false, 
              label: 'Nueva',
              icon: 'pi pi-fw pi-user-plus',
              command: () => {
                this.router.navigate(['/invoices/new']);
              },
            },
           
            {
              visible: this.userService.getPermiso('delivery','read') ?? false, 
              label: 'Search',
              icon: 'pi pi-fw pi-users',
              items: [
                {
                  label: 'Filter',
                  icon: 'pi pi-fw pi-filter',
                  items: [
                    {
                      label: 'Print',
                      icon: 'pi pi-fw pi-print',
                    },
                  ],
                },
                {
                  icon: 'pi pi-fw pi-bars',
                  label: 'List',
                },
              ],
            },
          ],
        },
        {
          visible: this.userService.getPermiso('options','read') ?? false, 
          label: 'opciomes',
          icon: 'pi pi-fw pi-calendar',
          items: [
            {
              visible: this.userService.getPermiso('address','write') ?? false, 
              label: 'Registrar',
              icon: 'pi pi-fw pi-pencil',
              items: [
                {
                  label: 'Pais',
                  icon: 'pi pi-fw pi-pencil',
                  command: () => {
                    this.router.navigate(['/countries/new']);
                  },
                },
                {
                  label: 'Provincia',
                  icon: 'pi pi-fw pi-pencil',
                  command: () => {
                    this.router.navigate(['/provinces/new']);
                  },
                },
                {
                  label: 'Localidad',
                  icon: 'pi pi-fw pi-calendar-minus',
                  command: () => {
                    this.router.navigate(['/towns/new']);
                  },
                },
              ],
            },
            {
              visible: this.userService.getPermiso('address','read') ?? false, 
              label: 'Listar',
              icon: 'pi pi-fw pi-pencil',
              items: [
                {
                  label: 'Paises',
                  icon: 'pi pi-fw pi-pencil',
                  command: () => {
                    this.router.navigate(['/countries']);
                  },
                },
                {
                  label: 'Provincias',
                  icon: 'pi pi-fw pi-pencil',
                  command: () => {
                    this.router.navigate(['/provinces']);
                  },
                },
                {
                  label: 'Localidades',
                  icon: 'pi pi-fw pi-calendar-minus',
                  command: () => {
                    this.router.navigate(['/towns']);
                  },
                },
              ],
            },
          ],
        },
        {
          visible: this.userService.getPermiso('inventario','read') ?? false, 
          label: 'Inventario',
          icon: 'pi pi-fw pi-calendar',
          items: [
            {
              visible: this.userService.getPermiso('inventario','read') ?? false, 
              label: 'scan',
              icon: 'pi pi-fw pi-pencil',
              command: () => {
                this.router.navigate(['/scan']);
              },
            },
            {
              visible: this.userService.getPermiso('inventario','read') ?? false, 
              label: 'Listar',
              icon: 'pi pi-fw pi-pencil',
              command: () => {
                this.router.navigate(['/inventario/list']);
              },
            },
            {
              visible: this.userService.getPermiso('inventario','write') ?? false, 
              label: 'Nuevo',
              icon: 'pi pi-fw pi-pencil',
              command: () => {
                this.router.navigate(['/inventario/new']);
              },
            },
            {
              visible: this.userService.getPermiso('inventario','read') ?? false, 
              label: 'Salida',
              icon: 'pi pi-fw pi-pencil',
              command: () => {
                this.router.navigate(['/inventario/out']);
              },
            },
            {
              visible: this.userService.getPermiso('inventario','read') ?? false, 
              label: 'Entrada',
              icon: 'pi pi-fw pi-pencil',
              command: () => {
                this.router.navigate(['/inventario/in']);
              },
            },
            {
              visible: this.userService.getPermiso('inventario','read') ?? false, 
              label: 'Chekeo',
              icon: 'pi pi-fw pi-pencil',
              command: () => {
                this.router.navigate(['/inventario/ckeck']);
              },
            },
            {
              separator:true
            },
           
            {
              label: 'Marca',
              items: [
                {
                  visible: this.userService.getPermiso('inventario','read') ?? false, 
                  label: 'Listar',
                  icon: 'pi pi-fw pi-pencil',
                  command: () => {
                    this.router.navigate(['/marca/list']);
                  },
                },
                {
                  visible: this.userService.getPermiso('inventario','write') ?? false, 
                  label: 'Nuevo',
                  icon: 'pi pi-fw pi-pencil',
                  command: () => {
                    this.router.navigate(['/marca/new']);
                  },
                },
              ],
            },
            {
              separator:true
            },
  
            {
              label: 'Reparaciones',
              items: [
                {
                  label: 'Listar',
                  icon: 'pi pi-fw pi-pencil',
                  command: () => {},
                },
                {
                  label: 'Nuevo',
                  icon: 'pi pi-fw pi-pencil',
                  command: () => {},
                },
              ],
            },
            
          ],
        },
      ];
  
      this.userMenu = [
        {
          label: 'Perfil',
          icon: 'pi pi-pencil',
        },
        {
          label: 'Logout',
          icon: 'pi pi-power-off',
          command: () => {
            this.userService.logout();
          },
        },
        {
          separator:true
        },
        {
           style: {'font-size':'0.6rem'},
          label: this.userEmail!
          
        }
      ];
  
   
  }
}
