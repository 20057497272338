import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../services/user/user.service';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-forgotten',
  templateUrl: './forgotten-pass.component.html',
  styleUrls: ['./forgotten-pass.component.scss'],
  providers:[MessageService]
})
export class ForgottenComponent implements OnInit {
  constructor(private fb:FormBuilder,
              private userService:UserService,
              private router:Router,
              private messageService: MessageService ) { }

ngOnInit(): void {
  
}
  public formSubmit = false;

  public forgottenForm= this.fb.group({
    email:['',[Validators.required,Validators.pattern(/^[^@]+@[^@]+\.[a-zA-Z]{2,}$/)]]
  });


  @HostListener ("submit", ["$event"]) onSubmit(event:Event) {
      event.preventDefault();

    if ( ! this.forgottenForm.valid ){
      this.messageService.add({
        key: "msg",
        severity: 'error',
        summary:"MDG Info",
        detail: 'Proporcione un correo válido'
      });
      console.log('correo invalido');
      
      return;
    }

    // recuperacion de contraseña
    const datos = {
      email: this.forgottenForm.get('email')?.value || null
    }
    this.userService.passForgotten( datos ).subscribe({
      next:(() => {
          //mensaje de ok
          this.messageService.add({
            key: "msg",
            summary:"MDG Info",
            severity: 'info',
            detail: 'Revise su buzon de correo'
          });
          // Rediregir al login
          setTimeout(() => {
            this.router.navigate(['/login']);
            
          }, 3000);
        
      }),
      error: (() => {
        this.messageService.add({
          key: "message",
          severity: 'error',
          summary:"MDG Info",
          detail: 'Se ha producido un error'
        });
        //Rediregir al login
        setTimeout(() => {
          this.router.navigate(['/login']);
          
        }, 3000);
      })
    })

  }

}
